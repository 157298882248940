import React from 'react';
import './App.scss';
import ATIHeader from './component/header/ATIHeader'
import Home from './component/home/Home'
import Solutions from './component/solution/Solutions'
import { BrowserRouter as Router, Route } from "react-router-dom";
import Contact from './component/contact/Contact'
import ATIFooter from './component/footer/ATIFooter';
import About from './component/about/About'
import Blog from './component/blog/Blog'

function App() {


  return (
    <div className="appDisplay">
      <Router>
        <ATIHeader />
        <div className="appBody">
          <Route exact path="/" component={Home} />
          <Route  path="/services" component={Home} />

          <Route path="/technology" component={Solutions} />
          <Route path="/contact" component={Contact} />
          <Route path="/about" component={About} />
          <Route path="/blog" component={Blog} />


        </div>

        <ATIFooter />

      </Router>

    </div>
  );
}

export default App;
