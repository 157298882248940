import React from 'react'
import './contact.scss'
import { Card, Icon } from 'semantic-ui-react'

import vid from '../../assets/contact.mp4'
import { tsConstructorType } from '@babel/types';
class Contact extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            lat: 43.8142138,
            lng: -79.4518589,
            zoom: 14
        }
    }

    render() {
        const position = [this.state.lat, this.state.lng];

        return (
            <div >

                <video src={vid} autoPlay loop className="solutionImg" />
                <div className="soltuionText">
                    <h1 className="soltuionText_1">Contact Us >>></h1> <br />
                    <h1 className="soltuionText_2">Let's work together</h1>
                </div>

                <div className="bodyContent">
                    <Card centered color="green" style={{ width: "50%", height: '60%' }}>
                        <Card.Content>
                            <Card.Header>ANAND TECH INC</Card.Header>
                            <Card.Meta><a href="https://anandtechinc.com">anandtechinc.com</a></Card.Meta>
                            <Card.Description>
                                Unit 31
      </Card.Description>
                            <Card.Description>
                                151 Townsgate Dr
      </Card.Description>
                            <Card.Description>
                                Vaughan,Ontario
      </Card.Description>
                            <Card.Description>
                                L4J 8J7
      </Card.Description>
                            <Card.Description>
                                Canada
      </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <Icon name='phone' />
                            +1 647 551 2353 <br />
                            <Icon name='mail' />
                            anandtechinc@gmail.com
    </Card.Content>
                    </Card>
                </div>
                <br />



            </div>

        )
    }
}

export default Contact
