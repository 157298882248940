import {Button, Header, Icon, Image} from 'semantic-ui-react'

import React from 'react'
import logo from '../../assets/atilogo.png'
import './header.scss'
import {Link} from "react-router-dom";
import {panes} from '../../constant/common'
import Modal from "react-bootstrap/Modal";


class ATIHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeItem: 'home',
            closeIcon: false
        }
    }


    handleItemClick = (e, {name}) => {
        this.setState({activeItem: name, closeIcon: false})

    }
    openmodal = () => {
        this.setState({closeIcon: true})
    }

    closeModal = () => {
        this.setState({closeIcon: false})
    }


    render() {
        var menus = panes.map((x, index) => {
            return <Button as={Link} to={x.path}
                           name={x.menuItem}
                           key={index}
                           aria-label={x.menuItem}
                           basic
                           size="huge"
                           active={this.state.activeItem === x.menuItem}
                           onClick={this.handleItemClick}>{x.menuItem}</Button>
        })
        var menusBurger = panes.map((x, index) => {
            return <Button as={Link} to={x.path}
                           name={x.menuItem}
                           key={index}
                           style={{width: '200px', marginTop: '20px'}}
                           aria-label={x.menuItem}
                           size="huge"
                           color="green"
                           active={this.state.activeItem === x.menuItem}
                           onClick={this.handleItemClick}>{x.menuItem}</Button>
        })
        return (
            <div className="mainHeader">
                <Header as="h2">


                    <div className="logo">
                        <Image src={logo} size="small"
                               alt="Anand Tech Inc"
                        />
                    </div>
                    <div className="title">
            <span>Anand Tech Inc.
</span>
                        <div className="burgerMenu">

                            <Icon onClick={
                                this.openmodal
                            } name="bars"></Icon>

                            <Modal show={this.state.closeIcon} onHide={this.closeModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Anand Tech Inc</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}> {menusBurger}</Modal.Body>
                                <Modal.Footer>

                                </Modal.Footer>
                            </Modal></div>
                    </div>

                    <div className="headerMenu">

                        <Button.Group>
                            {menus}
                        </Button.Group>


                    </div>


                </Header>
            </div>


        )
    }
}

export default ATIHeader
