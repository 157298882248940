import React from 'react'
import logo from '../../assets/notebook.jpg'
import { Card, Icon, Divider } from 'semantic-ui-react';
import YouTube from 'react-youtube';
import { blogVideos } from '../../constant/common'
const opts = {
    // height: '390',
    // width: '640',
    playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1
    }
};
class Blog extends React.Component {
    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    buildBlogVideos = () => {
        var elements = [];
        blogVideos.map((x, index) => {
            elements.push(
                <section>     
                    <h3>{x.title}</h3>                      
                     <YouTube
                    videoId={x.youtubeVideoId}
                    opts={opts}
                    onReady={this._onReady}
                />
                </section>

            );
            elements.push(<Divider></Divider>)
        });
        return elements;

    }

    render() {

        return (
            <div >

                <img src={logo} alt="Blog" className="solutionImg" />
                <div className="soltuionText">
                    <h1 className="soltuionText_1">Blogs >>></h1> <br />
                    <h1 className="soltuionText_2">Share Knowledge</h1>
                </div>
                <div style={{justifyContent:'center',display:'flex',flexDirection:'column',alignItems:'center'}}>
                    {this.buildBlogVideos()}
                </div>
            </div>
        )
    }
}
export default Blog