import React from 'react'
import vid from '../../assets/rocket.mp4'
import { Button, Header } from 'semantic-ui-react'
import './solution.scss'
import {SOL_CONST} from './solution-constant'
const square = { width: 150, height: 150,marginLeft:'40px',marginTop:'40px' }

export default class Solutions extends React.Component {
    renderSegment = () => {
        let components=[]

        SOL_CONST.map((x,index)=>{
            components.push(
                <Button  style={square} inverted color={x.color} key={index}>
                    <Header as='h2'>
                       {x.label}
                    </Header>
                </Button>
            )
        })
        return components
    }

    render() {
        return (
            <div >

                <video src={vid} autoPlay loop muted className="solutionImg" />
                <div className="soltuionText">
                    <h1 className="soltuionText_1">Technology >>></h1> <br />
                    <h1 className="soltuionText_2">Innovation with speed for customer success</h1>
                </div>
                <div className="bodyContent">
                {this.renderSegment()}
                <br/>
                </div>
                <br/>
                </div>
        )
    }
}