import React from 'react'
import vid from '../../assets/ny.mp4'
import { Card, Icon } from 'semantic-ui-react'


export default class About extends React.Component {
  render() {
    return (

      <div >

        <video src={vid} autoPlay loop muted className="solutionImg" />
        <div className="soltuionText">
          <h1 className="soltuionText_1">About Us >>></h1> <br />
          <h1 className="soltuionText_2">Team ATI</h1>
        </div>


        <div className="bodyContent">
          <Card centered color="green" style={{ width: "80%", height: '80%' }}>
            <Card.Content>
              <Card.Header>About Us</Card.Header>
              <Card.Meta><a href="https://anandtechinc.com">anandtechinc.com</a></Card.Meta>
              <Card.Description>
              Anand Tech Inc is a fastest growing ,proudly Canadian technology corporation
            

              </Card.Description>
              <br/>
              <Card.Description>
              We provide a full range of research and development services in software development. 
              We have strong financial and banking sector expertise in providing consulting services for IT architecture development, process optimization, software development and integration to solve our customers' business problems.

              </Card.Description>

              <br/>
              <Card.Description>
           
              </Card.Description>


      


            </Card.Content>
            <Card.Content extra>
              <Icon name='phone' />
              +1 647 551 2353 | +1 905 886 3457<br />
              <Icon name='mail' />
              anandtechinc@gmail.com
</Card.Content>
          </Card>
        </div>
        <br/>
      </div>
    )
  }
}