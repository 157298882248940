import React from 'react'
import vid from '../../assets/ipad.mp4'
import { Button, Header,Transition } from 'semantic-ui-react'
import {HOME_CONST} from './home-constant'

const square = { width: 200, height: 150,marginLeft:'40px' ,marginTop:'40px'}

class Home extends React.Component {
    renderSegment = () => {
        let components=[]

        HOME_CONST.map((x,index)=>{
            components.push(
                <Transition.Group animation="slide up" duration={1000} key={index}>

                <Button  style={square} inverted color={x.color} key={index}>
                    <Header as='h2'>
                       {x.label}
                    </Header>
                </Button>
                </Transition.Group>
            )
        })
        return components
    }
    render() {
        return (
            <div >

                <video src={vid} autoPlay muted loop className="solutionImg" />
                <div className="soltuionText">
                    <h1 className="soltuionText_1">Services >>></h1> <br />
                    <h1 className="soltuionText_2">Cloud -Web -Mobile Development</h1>
                </div>
                <div className="bodyContent">
                    {this.renderSegment()}
                </div>
                <br/>
                </div>
        )
    }
}
export default Home