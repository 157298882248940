import React, {useState} from 'react'
import {Button, Icon} from 'semantic-ui-react'
import './footer.scss'
import Carousel from "react-bootstrap/Carousel";
import tdbank from '../../assets/td.jpeg'
import bns from '../../assets/scotiabank.jpeg'
import citi from '../../assets/citi.jpeg'
import bnp from '../../assets/bnp.jpeg'
import ncr from '../../assets/NCR.jpeg'
import pwc from '../../assets/pwc.jpeg'
import Card from "react-bootstrap/Card";

const openSmp = () => {
    window.open('https://www.facebook.com/anandtechinc/', "_blank")
}

const openLI = () => {
    window.open('https://www.linkedin.com/company/anand-tech-inc/?viewAsMember=true', "_blank")
}

const openYT = () => {
    window.open('https://www.youtube.com/channel/UCDHjPeMr4QuytcU8qAH-zgA?view_as=subscriber', "_blank")
}

const openTR = () => {
    window.open('https://twitter.com/AnandTechInc1', "_blank")
}


export default function ATIFooter(props) {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <footer>
            <div style={{display:'flex',flexDirection:'column'}}>
            <h1><b>Proudly supported our clients below</b></h1>

            <div>

                <Carousel activeIndex={index} onSelect={handleSelect} height={200} slide={true}  >

                    <Carousel.Item>
                            <img variant="top" src={tdbank}       className="car_image"/>



                        <Carousel.Caption>
                            <h3>TD Bank</h3>
                            <p>Credit cards developments</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img variant="top" src={bns}       className="car_image"/>


                        <Carousel.Caption>
                            <h3>Scotia Bank</h3>
                            <p>AML,KYC developments</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img variant="top" src={citi}       className="car_image"/>

                        <Carousel.Caption>
                            <h3>Citi Bank USA</h3>
                            <p>ReactJS frameworks development</p>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img variant="top" src={bnp}       className="car_image"/>

                        <Carousel.Caption>
                            <h3>BNP Paribas</h3>
                            <p>IT Services app development</p>
                        </Carousel.Caption>
                    </Carousel.Item>


                    <Carousel.Item>
                        <img variant="top" src={ncr}       className="car_image"/>

                        <Carousel.Caption>
                            <h3>NCR</h3>
                            <p>Cheque deposit and scan </p>
                        </Carousel.Caption>
                    </Carousel.Item>


                    <Carousel.Item>
                        <img variant="top" src={pwc}       className="car_image"/>

                        <Carousel.Caption>
                            <h3>PwC USA</h3>
                            <p>Amplify product development</p>
                        </Carousel.Caption>
                    </Carousel.Item>

                </Carousel>
            </div>
                <br/>
            <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>

                <Button.Group>
                    <Button color='facebook' onClick={openSmp}>
                        <Icon name='facebook'/>
                    </Button>
                    <Button color='twitter' onClick={openTR}>
                        <Icon name='twitter'/>
                    </Button>
                    <Button color='linkedin' onClick={openLI}>
                        <Icon name='linkedin'/>
                    </Button>
                    <Button color='youtube' onClick={openYT}>
                        <Icon name='youtube'/>
                    </Button>
                </Button.Group><br/>

            </div>

            <div className="footerCls">
                <div>
                    <div>
                        &copy;2018-2022 Anand Tech Inc. All rights reserved

                    </div>
                    <div>
                        powered by <a href="https://anandtechinc.com">Anand Tech Inc</a>

                    </div>

                    <div>
                        <a href="/contact">Contact Us</a>
                    </div>
                    <div>
                        <a href="/about">About Us</a>
                    </div>
                    <div>
                        <a href="/services">What we offer</a>
                    </div>
                    <div>
                        <a href="/technology">Technology</a>
                    </div>
                    <div>
                        <a href="/blog">Blog</a>
                    </div>

                </div>


            </div>

            </div>

        </footer>
    )

}
