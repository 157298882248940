export  const common={
    IS_IMAGE:"Y"
}
export const panes = [
    { menuItem: 'Services', path: '/services' },
    { menuItem: 'Technology', path: '/technology' },
    { menuItem: 'Contact us', path: '/contact' },
    { menuItem: 'About us', path: '/about' },
    { menuItem: 'Blogs', path: '/blog' },
  
  ]
  export const blogVideos = [
    { youtubeVideoId: 'PFWdDSzpw1A', title: 'React Class to React Hooks' },
    { youtubeVideoId: 'GubSsqyiWxU', title: 'Angular 5 Material with sidenav' },
    { youtubeVideoId: 'WRAQEV4v9fM', title: 'Angular 2 with npm' },
  
  ];


export const OSM="https://osm.org/go/ZX6RACcQR-?m="
export const GOOGLE_MAP="https://www.google.com/maps/place/7900+Bathurst+St+%23421,+Thornhill,+ON+L4J+0B8/@43.8161573,-79.4536049,659m/data=!3m2!1e3!4b1!4m5!3m4!1s0x882b2c3e2564d4e3:0x57a869029af440e!8m2!3d43.8141596!4d-79.4517302"